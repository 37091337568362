.chips-box-btn {
  padding: 5px 15px !important;
  color: #fff;
  background-color: #f18d1f;
}

.img-profile {
  max-width: 100%;
  height: auto;
}

.resources-list li:last-child {
  font-size: 20px !important;
}