.react-datepicker-wrapper {
  width: inherit;
}

.img-upload {
  width: 100%;
  margin-top: 30px;
}

.user-profile-img {
  max-width: 250px;
  max-height: 250px;
}