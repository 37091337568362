.file-list {
  border: 1px solid #e1e1e1;
  padding: 1rem;
}

.chips-box-btn {
  padding: 5px 15px !important;
  color: #fff;
  background-color: #f18d1f;
}

.img-profile {
  max-width: 100%;
  height: auto;
}

.dropdown-item {
  padding: 0 1.5rem !important;
}

.story-text {
  word-wrap: break-word;
}

.resources-list li:last-child {
  font-size: 20px !important;
}