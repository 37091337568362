.table-header{
    background-color: #F18121 !important;
    color: white;
}

@media only screen and (max-width: 767px){
    .rs-cart.allCampaignsTable .cart-wrap table.cart-table td, 
    .rs-cart.allCampaignsTable .cart-wrap table.cart-table th{
        display: table-cell;
        border-right: 0;
    }

    .cart-wrap{
        overflow-x: auto;
    }
}