.custom-pop-up-overlay {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
    z-index: 10000;
    overflow:auto;
  }
  .custom-pop-up-popup {
    margin: 15px auto;
    padding: 20px;
    background: #fff;
    border-radius: 5px;
    width: 40%;
    position: relative;
    transition: all 5s ease-in-out;
    z-index: 100000;
  }
  
  .custom-pop-up-popup h2 {
    margin-top: 0;
    color: #333;
    font-family: Tahoma, Arial, sans-serif;
  }
  .custom-pop-up-popup .close {
    position: absolute;
    top: 20px;
    right: 30px;
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: #333;
  }
  .custom-pop-up-popup .close:hover {
    cursor: pointer;
    color: #000;
  }
  .custom-pop-up-popup .content {
    max-height: 30%;
    
  }
  .contact-page-popup-section{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 950px) {
    .custom-pop-up-popup {
      width: 45%;
    }
  }
  @media screen and (max-width: 850px) {
    .custom-pop-up-popup {
      width: 50%;
    }
  }
  
  @media screen and (max-width: 700px) {
    .custom-pop-up-popup {
      width: 70%;
    }
  }
  @media screen and (max-width: 600px) {
    .custom-pop-up-popup {
      width: 85%;
    }
  }
  
  .contact-us-form-label{
    font-size: 13px;
    margin-left: 1px;
    color:#848484;
    display: block;
  }
  .contact-us-form-label-required{
    color:red;
    font-size: 16px;
  }
  .contact-us-form-required{
    color:red;
    font-size: 14px;
    font-style: italic;
    text-align: right;
    margin-top: -25px;
    margin-bottom: 25px;
    padding-right: 5px;
  }
  .contact-us-form-field-error{
    font-size: 13px;
    margin-left: 1px;
    color:red;
    display: block;
    margin-top: -0.7rem
  }