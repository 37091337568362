.dropdown-menu {
  min-width: 0;
}

.feedback-input {
  border: 1px solid #ccc;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.chips-box-btn {
  padding: 5px 15px !important;
  color: #fff;
  background-color: #f18d1f;
}

.story-text {
  word-wrap: break-word;
}

.img-profile {
  max-width: 100% !important;
  height: auto !important;
}

.resources-list li:last-child {
  font-size: 20px !important;
}