.feature-bg {
  background: #00aa15 !important;
  padding: 3px 13px;
  color: white;
  font-weight: 500;
  text-align: center;
}

.option-divider{
color: #928d8d;
}
