.trash-icon{
  margin-left:-10% !important;
  color: red;
  margin-bottom: -47px;
  margin-top: 25px;
  text-align: center;
}

.check-icon {
  position: absolute;
  top: 5px;
  right: 10px;
  color: red;
}

.url-input{
  margin-top: 90px !important;
}

.save-btn{
  margin-left: -26px !important;
  width: 65% !important;
}

.payment-table{
  margin-left: -25px;
}

.payment-list .check-style .checkmark:after {
  border-color: transparent transparent #fd0000 #ff0000;
  border-width: 3px;
}

.requestor-payment-option .fa-pencil, .requestor-payment-option .fa-trash {
  color: #848484;
}

.requestor-payment-option .fa-check{
  color: green;
  width: 40px;
  // border: 1px solid blue;
  text-align: center;
}

.requestor-payment-option .fa-close{
  color: red;
  width: 40px;
  text-align: center;
  // border: 1px solid blue;
}

.requestor-payment-option .rowEditUrlInput{
  min-width: 250px;
}