.progress-bar-main-div{
    display: flex;
}
.progress-bar-container-styles  {
    height: 20px;
    width: 100%;
    background-color: #e0e0de;
    border-radius: 50px;
  }

 

  .progress-bar-labelStyles {
    padding: 5px;
    font-weight: bold;
    vertical-align: top;
    display: inline-block;
    font-size: 12px;
    margin-top: -8px;
  }
  .progress-name{
      text-align: left;
      margin-bottom: 3px !important;
  }
  .progress-bar-total-name{
    margin-top: -2px;
    margin-left: 5px;
  }
