.scrollUp {
  position: fixed;
  background: #f18d1f;
  border-radius: 50%;
  height: 40px;
  font-size: 24px;
  font-weight: 600;
  width: 42px;
  color: #fff;
  line-height: 36px;
  transition: all 0.3s ease;
  margin-left: 2px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.4);
  text-align: center;
  bottom: 40px;
  cursor: pointer;
  right: 20px;
  z-index: 999;
}

.scrollUp:hover {
  background: #ed3600;
}

.fa-angle-up-icon {
  font-weight: 900;
}

.display-none {
  display: none;
}
