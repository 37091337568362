.details-holder {
  .accordion__button:focus {
    outline: none;
  }
}

.feedback-input {
  border: 1px solid #ccc;
  padding: 10px 15px;
  margin-bottom: 10px;
  width: 100%;
}

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.chips-box-btn {
  padding: 5px 15px !important;
  color: #fff;
  background-color: #f18d1f;
}

.donor-camp-details .details-page-holder .chips-box{
  white-space: nowrap;
}

.donor-camp-details .details-page-holder td .dropdown{
  margin-top: 0 !important;
}

.donor-camp-details .details-page-holder td{
  vertical-align: middle;
}

.table td, .table th {
  vertical-align: middle !important;
}

.story-text {
  word-break: break-all !important;
}

.img-profile {
  max-width: 100% !important;
  height: auto !important;
}

.resources-list li:last-child {
  font-size: 20px !important;
}
