.file-wrapper {
  box-shadow: 0 0 30px #eee;
  width: 100%;
  max-width: 100%;
  opacity: 1;
  border-radius: 3px;
  border: 1px solid #ffffff;
  color: #767676;
  background: #ffffff;
  padding: 10px 18px;
  height: 50px;
}