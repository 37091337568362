.hover {
  cursor: pointer;
}

.hover:hover {
  color: #f18d1f;
}

.footer_social li {
  color: #f18d1f;
  font-size: 25px;
  margin-right: 20px;
}

.campaign-details-container {
  min-height: 500px;
}

.dashboard-header .user-dashboard {
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  text-align: center;
  height: 90px;
  /* border: 1px solid; */
  top: 0;
}

.form-div .from-control.quantityDropdown {
  padding-left: 5px;
  max-width: 60px;
}

.cursor-default {
  cursor: default;
}

.received_msg{
  padding-left: 40px;
}

.sent_msg_img{
    display: inline-block;
    right: 0;    
    position: absolute;
    bottom: 35px;
}

.sent_msg_img img{
  width: 30px;
    height: 30px;
    border-radius: 50%;
}

.sent-msg-ctnr{
  padding-right: 40px;
}

.incoming_msg_img{
  bottom: 35px;
}

.chip{
  border-radius: 0;
}

.tips-btn{
  width: 40px;
  height: 40px;
  padding: 10px;
}

.tips-btn .fa{
  font-size: 21px;
}

.small-tip-btn{
  width: 30px;
  height: 30px;
  padding: 0px;
}

.small-tip-btn .fa{
  font-size: 15px;
}

.small-tip-btn img{
  width: 64%;
}